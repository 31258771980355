import React, { Fragment } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import BookValuation from './BookValuation/BookValuation';
import PropertySection from './PropertySection/PropertySection';
import ButtonsGroup from './ButtonsGroup/ButtonGroup';
import './propertyDetails.scss';

const PropertyDetail = () => {
	return (
		<Fragment>
			<Header />
			<PropertySection />
			<BookValuation />
			<Footer />
			<ButtonsGroup/>
		</Fragment>
	)
}

export default PropertyDetail;
