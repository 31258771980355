import React, { Fragment } from 'react';
import PropertyDetails from '../../Components/PropertyDetails/PropertyDetails';

const PropertyDetail = () => {
	return (
		<Fragment>
			<PropertyDetails />
		</Fragment>
	)
}

export default PropertyDetail;
